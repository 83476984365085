import articleData from "../../resources/articles";

const data = {
  title: [
    "20. Double Glory",
    "19. MVP King",
    "18. Work Ethic",
    "17. Better Myth",
    "16. Draft Steal",
    "15. Physical Limitations",
    "14. Montana’s Pick",
    "13. Total Dedication",
    "12. Playoff Master",
    "11. Great Teammate",
    "10. Head-to-Head",
    "9. Comeback King",
    "8. Belichick’s Pick",
    "7. Pro Bowl Star",
    "6. New Playbook",
    "5. All-Time Completions",
    "4. Age is Just a Number",
    "3. Untarnished Legacy",
    "2. 700 Touchdowns",
    "1. Super Bowl Master",
  ],
  text: [
    "One of the main arguments against Brady’s greatness was his long-term coach. Some critical fans claimed that Brady couldn’t win without Bill Belichick. When he moved to Tampa, they expected a rapid decline. It was a chance for one last payday before sailing off into the sunset. However, Brady had other ideas.  ",
    "Both of these men have obviously enjoyed several great seasons. They also have three NFL MVP awards each to their names. However, this comes with a caveat. It’s arguably more challenging for a brilliant player to win it in a great team. Patrick Mahomes arguably deserved the prize as much as Rodgers in 2020. But there is one area where Brady blows Rodgers out of the water.",
    "Brady’s work ethic is unquestionable. A video emerged in 2020 of the quarterback furiously chastising his teammate. They enraged him because they weren’t performing up to his high standards. To show such dedication at the age of 43 was impressive. A few months later, there were questions about Rodgers’ commitment to the NFL. This came immediately after he won the MVP award.",
    "There’s an argument that Brady always plays with better players. This isn’t entirely true because it’s a matter of perspective. Sure, he’s enjoyed superior weapons over the years. Rodgers had Davante Adams at his disposal over the past couple of years. But he still put up superb numbers without much else. There is a myth that Rodgers’ defense is always worse than Brady’s.",
    "Many people regard Brady as the biggest draft steal ever. In comparison, Rodgers was a bonafide contender for the No.1 pick. It was a surprise in 2005 when the 49ers chose Alex Smith instead. But Rodgers still went late in the first round. This was always going to be the case because of his college reputation. Famously, Brady was the 199th overall pick in the sixth round of the 2000 draft.",
    "There’s no denying that Rodgers is a better athlete than Rodgers. But this doesn’t mean he’s a better quarterback. First, Rodgers is extremely mobile and can be a genuine rushing touchdown threat. This is the opposite of Brady who has the footwork of a paraplegic snake. Meanwhile, Rodgers has a stronger arm. Despite his weaknesses, Brady is still arguably the best.",
    "Joe Montana knows what it means to be an all-time great. The San Francisco icon has a good claim to be the greatest himself. He’s a four-time Super Bowl champion as well as a three-time Super Bowl MVP. However, he admitted in 2021 that Brady’s CV speaks for itself. He didn’t even mention Rodgers in the conversation because he’s not. In short, Brady is on another level.",
    "In 2019, fans started to write Brady off. The Patriots fell out of the playoffs early and then Brady left. Many of them expected him to move to San Francisco but he chose Tampa. Some people thought he’d undergo an instant decline. They also believed that he’d struggle to adapt to Bruce Arians’ style of play. Perhaps Rodgers would finally outclass Brady in the league (via Daily Mail).",
    "Brady is the undisputed king of the playoffs. There’s no way for Rodgers to outclass him here unless he wins the Super Bowl for the next five years. Brady almost has as many postseason wins against NFC opponents as Rodgers. This is despite playing in the AFC for 20 seasons. Rodgers has 11 wins against his conference rivals (via Sportscasting). Meanwhile, Brady has nine victories against NFC opposition.",
    "Aaron Rodgers’ teammates don’t like him. That’s according to a former NFL GM (via Bleacher Report) “The guy’s selfish,” the GM said. “Teammates don’t like him. Coaches don’t like him. It’s all about Aaron. They all deal with him because he’s a superstar.” This is a damning indictment of the Green Bay star’s influence in the locker room. It appears that Brady seems to outclass him in this area.",
    "Brady and Rodgers have enjoyed long NFL careers. But incredibly they’ve only played four times against each other. As they play in different conferences the opportunities are slim. However, Brady has the upper hand when it comes to their head-to-head record. His teams have beaten Rodgers three times with a single defeat. Of course, it’s not entirely fair to base their performances on the results.",
    "Brady teams have a much better record of achieving comebacks than Rodgers’. The latter is phenomenal when his team takes the lead. But his overall ability to inspire a team to victory from a losing position is grim. In 2018, Brady had a 109-64 record from a losing position. In contrast, Rodgers only had a 48.55 record. Brady also continued to outclass Rodgers from the same position in the postseason.",
    "Bill Belichick is the most successful coach in NFL history. Rival fans despise him but his knowledge of the sport must be respected. He also had an interesting take on the best quarterback he coached against. However, Rodgers didn’t even enter the conversation. Instead, he opted for Peyton Manning. Admittedly, the Patriots don’t play the Packers very often. But it was still interesting to see Belichick’s perspective.",
    "Super Bowls aren’t always a fair reflection of an individual player’s ability. Dan Marino never won a ring because Miami never had a strong defense. But he is unanimously one of the greatest signal-callers ever. Brady’s unique record with seven rings as well as success with two franchises is unique. However, their Pro Bowl selection rate is an interesting comparison (via Sportskeeda).",
    "We’ve spoken about Brady’s move to Tampa. But this came with a unique challenge. He was accustomed to Bill Belichick’s style of play for 20 years. Suddenly, he had to learn Bruce Arians’ playbook. Make no mistake, this was a massive challenge for an older quarterback. Nobody knows if Rodgers would prosper in such a situation. Maybe he’d blossom but Brady proved his ability.",
    "At 44, Tom Brady led the NFL in passing yards in 2021. This was outrageous because of his age and the competition across the league. He continued to outclass rivals like Rodgers, Josh Allen, and Patrick Mahomes. It was staggering because he was so consistent. Furthermore, he was breaking records in every game he played. The Bucs faced the Buffalo Bills in December 2021 (via Bleacher Report).",
    "Tom Brady turned 44 years old in 2021. That number is staggering because of his brilliance on the field. His numbers continued to improve despite his age. Most athletes decline physically in their late thirties. But Brady is an outlier. He’s played better than he when he was in his twenties. Insanely, many regarded him as the top active quarterback in the league in December of that year.",
    "Rodgers was an uncontroversial figure for the majority of his career, then everything changed overnight. He suddenly demanded to leave Green Bay because he wanted to return to California. Furthermore, there was a vaccine controversy that tarnished him in the eyes of neutrals (via Medium.com). Brady successfully left the Patriots without burning bridges. His relationship with Bill Belichick was icy but publicly respectful.",
    "In 2021, Tampa defeated the Buffalo Bills as they continued their Super Bowl defense. After the game, Brady received a lot of praise because he broke some incredible records. Firstly, he broke the all-time completions record. Then, he achieved his 700th career touchdown pass. This was a remarkable personal accolade because it was the first time in NFL history (via Sportskeeda).",
    "Some analysts think it’s unfair to compare Brady’s Super Bowl record to Rodgers. The latter only has one ring after beating the Steelers in 2010. However, Mike McCarthy arguably wasted some of the best years of Rodgers’ career. They failed to build on their success. Meanwhile, Bill Belichick built a ruthless winning machine. Brady won six of his eight Super Bowl appearances with the team.",
  ],
  image: [
    // "https://cdn.sportscroll.com/wp-content/uploads/2021/03/gronkowsksbwin.jpeg",
    "https://cdn.sportscroll.com/wp-content/uploads/2020/10/tom-brady-buccaneers-celebration-4.jpg",
    "https://cdn.sportscroll.com/wp-content/uploads/2021/12/tom-brady_1rmulyq8a6od412xokakctm9ah.jpg",
    "https://cdn.sportscroll.com/wp-content/uploads/2021/12/r757140_1296x729_16-9.jpg",
    "https://cdn.sportscroll.com/wp-content/uploads/2019/08/2018-new-england-patriots-2.jpg",
    "https://cdn.sportscroll.com/wp-content/uploads/2021/09/aaron-rodgers5.jpg",
    "https://cdn.sportscroll.com/wp-content/uploads/2020/11/download-1-2.jpeg",
    "https://cdn.sportscroll.com/wp-content/uploads/2020/08/nfl_a_mmp-montana01jr_1296x729.jpg",
    "https://cdn.sportscroll.com/wp-content/uploads/2021/12/tom-brady-workout-and-diet-plan-3.jpg",
    "https://cdn.sportscroll.com/wp-content/uploads/2021/12/632421734.jpg-scaled.webp",
    "https://cdn.sportscroll.com/wp-content/uploads/2021/12/i.jpeg",
    "https://cdn.sportscroll.com/wp-content/uploads/2021/12/tom-brady-packers.jpg",
    "https://cdn.sportscroll.com/wp-content/uploads/2021/12/hi-res-c911406dedf3f227ee3fbc60de17abdb_crop_north-scaled.jpg",
    "https://cdn.sportscroll.com/wp-content/uploads/2019/08/2015-denver-broncos-1.jpeg",
    "https://cdn.sportscroll.com/wp-content/uploads/2021/12/https-musketfire.com-files-2016-01-tom-brady-nfl-afc-championship-new-england-patriots-denver-broncos-2.jpg",
    "https://cdn.sportscroll.com/wp-content/uploads/2020/11/bruce-arians-shy-placing-blame-on-tom-brady-loss.jpg",
    "https://cdn.sportscroll.com/wp-content/uploads/2020/01/drew-brees-saints.jpg",
    "https://cdn.sportscroll.com/wp-content/uploads/2021/12/hi-res-93d65b19cd691a288b7dad9be091ca97_crop_north.jpg",
    "https://cdn.sportscroll.com/wp-content/uploads/2021/12/ftc5hn9m7qirckajz8zp.jpeg",
    "https://cdn.sportscroll.com/wp-content/uploads/2021/12/1358660291.0.jpg",
    "https://cdn.sportscroll.com/wp-content/uploads/2021/12/yuolrhj7bydwflgoaxejvnkmku.jpg",
  ],
};

class ArticleService {
  articlesList = [];
  currentIndex = 0;
  count = 1;

  constructor() {
    this.genearetArticle();
  }

  genearetArticle() {
    const dataLength = 2;
    const dataStartId = this.randomInt(1000, 1500);
    const repeatingCount = 1;

    this.articlesList = this.generateArticleItem(
      dataLength,
      dataStartId,
      repeatingCount
    );
  }

  /**
   * Generate Article item
   * @param {number} count
   * @param {number} startId
   * @param {number} repeatCount how long should repeat elements
   * @returns {Array<{
   * 		id: number;
   *    title: string;
   * 		nodes: Array<{
   *      id: number;
   *      title: string;
  * 			content: Array<{
  *         type: string;
  *         content: string;
  *       }>;
   * 		}>;
   * }>}
   */
  generateArticleItem(count, startId, repeatCount) {
    const articles = [];
    const endPoint = startId + count;

    let index = 0;

    for (let i = startId; i < endPoint; i++) {
      
      if (!data.title[index]) {
        index= 0;
      }

      // one article duplicate but set unique id
      articleData[index].id = i;

      articles.push(articleData[index]);

      index++;
    }

    return articles;
  }

  /**
   *
   * @param {number} repeatCount
   * @returns {Array<{
   * 		tag: string;
   *     type: string;
   *     content: string;
   *     className: string;
   * }>}
   */
  generateOneContentItem(index, repeatCount) {
    const content = [];
    let lastId = 1;

    for (let i = 0; i < repeatCount; i++) {
      const { title, text, image } = this.getDependingData(index);

      // ** generate title for article item
      if (i == 0) {
        content.push({
          id: 1,
          tag: "h3",
          type: "text",
          content: title,
          className: "",
        });
      }

      // ** article item text content
      content.push({
        id: lastId + 1,
        tag: "p",
        type: "text",
        content: text,
        className: "",
      });

      // ** article item img
      content.push({
        id: lastId + 2,
        tag: "img",
        type: "img",
        content: image,
        className: "",
      });

      lastId += 2;
    }

    return content;
  }

  getDependingData(index) {
    let currentIndex = index;

    return {
      title: data.title[currentIndex],
      text: data.text[currentIndex],
      image: data.image[currentIndex],
    };
  }

  getData(count) {
    const result = {
      data: [],
      hasMore: true,
      dataLength: this.articlesList.length,
    };

    const restItemsCount = result.dataLength - this.currentIndex;
    const lessThenMust = restItemsCount < count;
    const endPoint =
      this.currentIndex + (lessThenMust ? restItemsCount : count);

    for (let i = this.currentIndex; i < endPoint; i++) {
      result.data.push(this.articlesList[i]);
    }

    // ** update current index
    this.currentIndex += count;

    result.hasMore = !lessThenMust;

    return result;
  }

  /**
   * Fetch articles async
   * @param {number} count
   * @param {number} startId
   * @param {number} repeatCount how long should repeat elements
   * @returns {Promise<any[]>}
   */
  async fetchArticles(count = this.count) {
    return new Promise((res, rej) => {
      setTimeout(() => res(this.getData(count)), 500);
    });
  }

  randomInt(min, max) {
    return Math.floor(Math.random() * (max - min + 1) + min);
  }
}

export default new ArticleService();
