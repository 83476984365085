import { useEffect, useRef, useState } from "react";
import { BrowserRouter, Route, Switch, Link } from "react-router-dom";
import Navbar from "./components/Navbar/Navbar";
import TestArticle from "./components/TestArticle/TestArticle";
import Posts from "./components/Posts/Posts";
import Ads from "./components/Ads";
import Home from "./components/Home/Home";
import "./App.scss";

console.warn = () => {};

function App() {
  const containerRef = useRef(null);

  useEffect(() => {
    setTimeout(() => {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }, 0);

    if (containerRef && containerRef.current) {
      containerRef.current.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }

    addDependingScripts();
  }, []);

  /**
   * Set gpt and prebid scripts in head
   */
  function addDependingScripts() {
    const gptScript = document.createElement("script");

    gptScript.src = "//www.googletagservices.com/tag/js/gpt.js";
    gptScript.async = true;

    document.head.appendChild(gptScript);
  }

  return (
    <BrowserRouter>
      <div className="App" ref={containerRef}>
        <Navbar />

        <div className="app-content">
          <Switch>
            <Route exact path="/" component={Home} />
            <Route
              exact
              path={[
                "/25-dramatic-sports-trade-sagas-that-became-huge-messes/*",
                "/25-dramatic-sports-trade-sagas-that-became-huge-messes",
              ]}
              component={TestArticle}
            />
            <Route path="/posts" component={Posts} />
            <Route path="/ads\.txt/" component={Ads} />
          </Switch>
        </div>
      </div>
    </BrowserRouter>
  );
}

export default App;
