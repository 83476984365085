class AdvertisingService {
  AD_UNITE_CODE_PREFIX = "SS_InContent_ra";
  NETWORK_ID = "21708480437";
  AD_UNIT_CODE = "SS_InContent_ra";




  desktopSizes = [
    [728, 90],
    [1,1],
  ];

  mobileSizes = [
    [1, 1],
    [1, 2],
    [250, 250],
    [300, 250],
    [320, 50],
    [336, 280],
  ];


  

  Bidders_mobile=[
    { bidder: "sovrn", params: { tagid: "630169" } },
    { bidder: "pulsepoint", params: { cf: '300x250', cp: 561847, ct: 698126 } },
    { bidder: "sharethrough", params: { pkey: "AcT8xJianPjsdEZ8v74qTJga" } },
    //{ bidder: 'undertone', params: { publisherId: 3660, placementId: "3660003" } },
    { bidder: "conversant", params: { site_id: "109536" } },
    {bidder: "ix", params: {siteId: "595671", size: [300, 250]}},
    { bidder: "appnexus", params: { placementId: "16368811" } },
    {bidder: 'openx', params: {unit: "540109140", delDomain: "spikemedia-d.openx.net"}},
    //{ bidder: "yieldmo", params: { placementId: "2245782465434780743" } },
    //{ bidder: "teads", params: { placementId: "113133", pageId: "104519" } },
    { bidder: "rhythmone", params: { placementId: "82972" } },
    { bidder: "triplelift", params: { inventoryCode: "SS_Incontent_Mobile_300x250_HDX" } },
    //{ bidder: 'vidazoo', params: { cId: "5e8dc157435bf900178298f6", pId: "59ac17c192832d0011283fe3"} },
    { bidder: "onemobile", params: { dcn: "8a9699d4017474e03107e06f3b780010", pos: "5102716" } },
    { bidder: "medianet", params: { cid: "8CU6D1T70", crid: "258974162" } },
    { bidder: "adform", params: {  mid: 1183480} },
    { bidder: "onetag", params: {  pubId:"7592249cd03a71e"} },
    { bidder: "amx", params: { tagId: "c3Bpa2VtZWRpYS5jbw" } },
    //{ bidder: "pubmatic", params: { publisherId: "899199",adSlot:"sportscroll_300x250_ATF"} },
    //{ bidder: "emx_digital", params: { tagid: "159148" } },
  ];

   Bidders_desktop =[
    { bidder: "sovrn", params: { tagid: "630170" } },
    { bidder: "pulsepoint", params: { cf: '728x90', cp: 561847, ct: 698127 } },
    { bidder: "sharethrough", params: { pkey: "jyJhoEuqS2HDwi29VxymiGyN" } },
    { bidder: "teads", params: { placementId: "113133", pageId: "104519" } },
    { bidder: 'undertone', params: { publisherId: 3660, placementId: "3660004" } },
    { bidder: 'undertone', params: { publisherId: 3660, placementId: "3660007" } },
    { bidder: "conversant", params: { site_id: "109536" } },
    { bidder: "appnexus", params: { placementId: "16368816" } },
    { bidder: "ix", params: {siteId: "595672", size: [728, 90]}},
    { bidder: 'openx', params: {unit: "540109140", delDomain: "spikemedia-d.openx.net"}},
    //{ bidder: "yieldmo", params: { placementId: "2284868959847745385" } },
    { bidder: "rhythmone", params: { placementId: "82972" } },
    { bidder: "triplelift", params: { inventoryCode: "SS_incontent_Desktop_728x90_HDX" } },
    { bidder: "onemobile", params: { dcn: "8a9694c7017474db0327dbc0066b009e", pos: "5102718" } },
    { bidder: "medianet", params: { cid: "8CU6D1T70", crid: "775941169" } },
    { bidder: "adform", params: {  mid: 1183584} },
    { bidder: "onetag", params: {  pubId:"7592249cd03a71e"} },
    { bidder: "amx", params: { tagId: "c3Bpa2VtZWRpYS5jbw" } },
    //{ bidder: "pubmatic", params: { publisherId: "899199",adSlot:"sportscroll_728x90_ATF"} },
    //{ bidder: "emx_digital", params: { tagid: "159148" } },
  ]

 

  VIEWPORT_OFFSET = 1400;
  PREBID_TIMEOUT = 1000;

  lastIndex = 1;
  newAdUnites = [];
  definedSlotsId = [];

  constructor() {
    this.getGoogleTag(true);
    this.getPrebidJs();
  }

  /**
   * Init advertising for ad
   * @param {string} adId
   */
  initAdvertising(adId) {
    this.log({
      text: `INIT - ID: ${adId}`,
    });
    this.defineGPTSlots(adId, (slot, adId) =>
      this.sendPrebidRequest(slot, adId)
    );
  }

  /**
   * Define GPT slot
   * @param {string} adId
   * @param {(slot) => void} onSlotDefined called after defination
   */
  defineGPTSlots(adId, onSlotDefined) {
    const googletag = this.getGoogleTag();
    const { adUnitCode, adUnitPath, sizes } = this.toGPTDefination(adId);

    this.log({
      text: `SLOT_DEFINATION - ID: ${adId}`,
    });

    googletag.cmd.push(function () {
      var slot = googletag
        .defineSlot(adUnitPath, sizes, adUnitCode)
        .addService(googletag.pubads());

      googletag.pubads().enableSingleRequest();
      googletag.enableServices();

      onSlotDefined(slot, adId);
    });
  }

  /**
   * Send request to prebid
   * @param {any} slot defined ad slot
   * @param {string} adId
   */
  sendPrebidRequest(slot, adId) {
    const pbjs = this.getPrebidJs();
    const adUnites = this.toPrebidAdUnites([adId]);

    this.log({
      text: `SEND_PBJS_REQUEST - ID: ${adId}`,
      objects: { adUnites },
    });

    this.moveAdUniteToDefined(adId);

    pbjs.que.push(() => {
      pbjs.adUnits = [];

      pbjs.addAdUnits(adUnites);
      pbjs.requestBids({
        bidsBackHandler: () => {
          this.initAdserver(slot, adId);
        },
        timeout: this.PREBID_TIMEOUT,
      });
    });
  }

  /**
   * Refresh slot specifically
   * @param {any} slot
   * @param {any} adId
   */
  initAdserver(slot = null, adId = null) {
    const googletag = this.getGoogleTag();
    const pbjs = this.getPrebidJs();

    googletag.cmd.push(() => {
      pbjs.que.push(() => {
        pbjs.setTargetingForGPTAsync([adId]);
        if (slot) {
          this.log({ text: "Slot's Refreshed", objects: { adId } });

          googletag.pubads().refresh([slot]);
        }
      });
    });

    this.log({
      text: "------------------------------------------------------- END SESSION -------------------------------------------------------",
    });
  }

  /**
   * Get googletag object
   * if not initalized set default value
   */
  getGoogleTag(initial = false) {
    var googletag = window.googletag || {};
    googletag.cmd = googletag.cmd || [];

    if (initial) {
      googletag.cmd.push(function () {
        googletag.pubads().disableInitialLoad();
      });
    }

    return googletag;
  }

  /**
   * Get prebid js object
   * if not initalized set default value
   */
  getPrebidJs() {
    var pbjs = window.pbjs || {};
    pbjs.que = pbjs.que || [];

    return pbjs;
  }

  /**
   * After initialize ad move adUniteId to definedSlotsId list
   * and remove from newAdUnites list
   * in definedSlotsId list saved all defined ids for after unmounting component destroy all it
   * @param {string} adUnitId
   */
  moveAdUniteToDefined(adUnitId) {
    const indexinNewAdunites = this.newAdUnites.indexOf(adUnitId);

    if (indexinNewAdunites != -1) {
      this.newAdUnites.splice(indexinNewAdunites, 1);

      this.definedSlotsId.push(adUnitId);
    }
  }

  /**
   * Get all defined slots object by adIds
   */
  getAllDefinedSlots() {
    const googletag = this.getGoogleTag();

    return googletag.pubads().getSlots();
  }

  /**
   * Destroy all slots
   */
  destroyAllDefinedSlots() {
    const googletag = this.getGoogleTag();
    const slots = this.getAllDefinedSlots();

    googletag.destroySlots(slots);
  }

  /**
   * Generate ad unites codes
   * @param {number} count
   */
  setNewAdUnites(count) {
    const newAdUniteKeys = [];
    const endPosition = this.lastIndex + count;

    for (let i = this.lastIndex; i < endPosition; i++) {
      newAdUniteKeys.push(`${this.AD_UNITE_CODE_PREFIX}_${i}`);
    }

    this.newAdUnites = [...newAdUniteKeys];
    this.lastIndex = endPosition;

    return this.newAdUnites;
  }

  /**
   * Generate ad unies list for prebid request
   * @param {string[]} adIds
   * @returns {any[]}
   */
  toPrebidAdUnites(adIds) {
    const adUnites = [];

    for (const key of adIds) {
      const requestItem = {
        code: key,
        mediaTypes: {
          banner: {
            sizes: this.mobileSizes,
          },
        },
          bids: this.Bidders_mobile,

      };

    
      if (window && window.innerWidth > 1200) {
        requestItem.bids=this.Bidders_desktop
      };

      if (window && window.innerWidth > 1200) {
        requestItem.mediaTypes.banner.sizes = this.desktopSizes;
      }

      adUnites.push(requestItem);
    }

    return adUnites;
  }

  /**
   * Generate slots list for gpt defination
   * @param {string} adUnitCode
   * @returns {{
   *    adUnitPath: string;
   *    adUnitCode: string;
   *    sizes: Array<Array[number]>;
   * }}
   */
  toGPTDefination(adUnitCode) {
    return {
      adUnitPath: `${this.NETWORK_ID}/${this.AD_UNIT_CODE}`,
      adUnitCode: adUnitCode,
      sizes: (window && window.innerWidth > 1200) ? this.desktopSizes : this.mobileSizes,

    };
  }

  /**
   * Generate ad block for add in DOM
   * @param {string} adId
   * @returns {HTMLDivElement}
   */
  generateAdBlock(adId) {
    // ** ad block parent container
    const adParentElement = document.createElement("div");
    adParentElement.setAttribute("class", "advertising-block");
    adParentElement.setAttribute("data-ad-insterted", "false");
    adParentElement.setAttribute("data-ad-id", adId);

    // ** ad block where must add ad
    const adDivElement = document.createElement("div");
    adDivElement.setAttribute("id", adId);

    // ** ad title element
    const pElement = document.createElement("p");
    pElement.setAttribute("class", "ad-title");
    pElement.innerText = "Advertisement";

    adParentElement.appendChild(pElement);
    adParentElement.appendChild(adDivElement);

    return adParentElement;
  }

  /**showed
   * Check is element in view Port or not
   * @param {HtmlElement} el
   * @param {HtmlElement} el
   * */
  isElementInViewport(el, offsetBottom = this.VIEWPORT_OFFSET) {
    const rect = el.getBoundingClientRect();

    return (
      rect.top >= 0 &&
      // rect.left >= 0 &&
      rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) + offsetBottom
      // rect.right <= (window.innerWidth || document.documentElement.clientWidth)
    );
  }

  /**
   * Reset all properties
   */
  reset() {
    this.definedSlotsId = [];
    this.newAdUnites = [];
  }

  /**
   * Colored console log
   * @param {{
   *    text: string;
   *    objects?: any;
   *  }} message
   * @param {string} color
   * @param {number} fontSize
   */
  log(message, color = null, fontSize = 32) {
    if (!color) {
      color =
        "#" + (0x1000000 + Math.random() * 0xffffff).toString(16).substr(1, 6);
    }

    console.log(
      `%c ${message.text}`,
      ` color: ${color};
        // background: ${color};
        border: 1px solid ${color};
        font-size:${fontSize};
        font-weight: bold;
        padding: 2px 3px;
        border-radius: 5px;
        box-shadow: 3px 2px 14px -5px #fff;`,
      message.objects || ""
    );
  }
}

export default new AdvertisingService();
