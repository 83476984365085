import React, { Fragment, useEffect } from "react";

/**
 * Component for render one article
 * @param {{
 *      id: number,
 *      content: Array<{
 *       tag: string;
 *       type: string;
 *       content: string;
 *       className: string;
 *   }>
 * }} props.articleItem
 * @param {string[]} adUnitKeys
 */
export default function ArticleItem({ articleItem, adUnitKeys }) {
  let lastAdIndex = 0;

  function renderElement(item) {
    let tag = "p";
    let elementProps = {
      className: "",
    };
    let children = item.content;

    if (item.type == "img") {
      elementProps.alt = "Article Image";
      elementProps.src = item.content;

      tag = "img";

      children = undefined;
    }

    if (item.tag == "FIGCAPTION") {
      tag = "figcaption";
    }

    return [undefined, ""].includes(item.content)
      ? null
      : React.createElement(tag, elementProps, children);
  }

  /**
   * Render ad block by adUnitKey
   * @returns {ReactNode}
   */
  function renderAdBlock() {
    if (!adUnitKeys.length) {
      return null;
    }

    const adUnitKey = adUnitKeys[lastAdIndex];

    lastAdIndex++;

    return (
      <div
        className="advertising-block"
        data-ad-insterted={false}
        data-ad-id={adUnitKey}
      >
        <p className="ad-title">Advertisement</p>
        <div id={adUnitKey} />
      </div>
    );
  }

  return (
    <div className="article-item col-lg-8 col-md-12">
      {articleItem.nodes.map((node) => (
        <div className="fragment" key={node.id}>
          <h3>{node.title}</h3>
          {node.content.map((item) => (
            <Fragment key={item.id}>
              {renderElement(item)}
              {item.tag == "FIGCAPTION" && renderAdBlock()}
            </Fragment>
          ))}
        </div>
      ))}
    </div>
  );
}
