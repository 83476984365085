import { useEffect, useMemo, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import ReactLoading from "react-loading";
import ArticleService from "./ArticlesService";
import AdvertisingService from "../../services/AdvertisingService";
import ArticleItem from "./ArticleItem";
import "./TestArticle.css";

let postIndex = 1;

export default function TestArticle(props) {
  const [articlesInfo, setArticlesInfo] = useState({
    data: [],
    hasMore: true,
    dataLength: 0,
  });
  const [adUniteKeysByIds, setAdUnitKeys] = useState({});

  useEffect(() => {
    fetchData();

    // ** inital custom call scroll event for maka action that ad which in viewport
    onScroll(new CustomEvent("scroll"));

    window.addEventListener("scroll", onScroll);

    return () => {
      window.removeEventListener("scroll", onScroll);

      AdvertisingService.destroyAllDefinedSlots();
    };
  }, []);

  /**
   * During scroll check which ad must send request
   * @param {HTMLScrollEvent} event
   */
  function onScroll(event) {
    const advertisingBlocks = document.querySelectorAll(
      ".advertising-block[data-ad-insterted='false']"
    );

    for (const adBlock of advertisingBlocks) {
      
      if (adBlock && AdvertisingService.isElementInViewport(adBlock)) {
        const adId = adBlock.getAttribute("data-ad-id");
        adBlock.setAttribute("data-ad-insterted", "true");
        console.log(adId);

        // ** initialize advertising send prebid request and define GPT slots
        AdvertisingService.initAdvertising(adId);
      }
    }
  }

  async function fetchData() {
    await ArticleService.fetchArticles().then((res) => {
      if (res && res.data) {
        const articlesInfoCp = { ...articlesInfo };

        articlesInfoCp.data.push(...res.data);
        articlesInfoCp.hasMore = res.hasMore;
        articlesInfoCp.dataLength = res.dataLength;

        setArticlesInfo(articlesInfoCp);

        makeAdvertising(res.data);
      }
    });

    const newUrl =
      window.location.origin +
      `/25-dramatic-sports-trade-sagas-that-became-huge-messes/${postIndex++}`;
    window.history.pushState("", "", newUrl);
  }

  function makeAdvertising(elements) {
    const adUnitKeysById = {};
    let imagesCount = 0;

    for (let i = 0; i < elements.length; i++) {
      const elem = elements[i];

      elem.nodes.forEach((node) => {
        node.content.forEach((item) => {
          if (item.type == "img") {
            imagesCount++;
          }
        });
      });
    }

    const adUniteKeys = AdvertisingService.setNewAdUnites(imagesCount);
    const cpAdUnitKeys = [...adUniteKeys];

    // **
    for (const el of elements) {
      const images = [];
      el.nodes.forEach((node) =>
        node.content.map((item) => {
          if (item.type == "img") {
            images.push(item);
          }
        })
      );

      if (images.length) {
        adUnitKeysById[el.id] = cpAdUnitKeys.splice(0, images.length);
      }
    }

    setAdUnitKeys({
      ...adUniteKeysByIds,
      ...adUnitKeysById,
    });

    // setAdsBlockInDOM(adUniteKeys);
  }

  /**
   * Set ad block in DOM after ads which 'data-ad-inserted' flag is false
   * @param {string[]} adUnitKeys
   */
  function setAdsBlockInDOM(adUnitKeys) {
    const needToInsert = document.querySelectorAll(
      "img[data-ad-inserted=false]"
    );

    for (let i = 0; i < adUnitKeys.length; i++) {
      const key = adUnitKeys[i];

      // ** ad block parent container
      const adParentElement = AdvertisingService.generateAdBlock(key);

      // ** image element after that must add ad block
      const imgElement = needToInsert[i];

      if (imgElement) {
        imgElement.after(adParentElement);

        imgElement.setAttribute("data-ad-inserted", "true");
      }
    }
  }

  function renderLoading() {
    return (
      <div className="loading-content">
        <ReactLoading type="bars" color="#dd3131" />
      </div>
    );
  }

  function getTopImage(articleItem) {
    let correctSrc = articleItem.imageLg;
    return correctSrc;
  }

  function renderPostBlock() {
    return (
      <div className="post_share_block">
        <ul className="post-share no-l-st">
          <li className="fb-color">
            <a target="_blank" href="">
              <i className="fa fa-facebook"></i> <span>Facebook</span>
            </a>
          </li>
          <li className="tw-color">
            <a target="_blank" href="">
              <i className="fa fa-twitter"></i> <span>Twitter</span>
            </a>
          </li>
          <li className="g-color">
            <a target="_blank" href="">
              <i className="fa fa-linkedin"></i> <span>Linkedin</span>
            </a>
          </li>
          <li className="p-color">
            <a target="_blank" href="">
              <i className="fa fa-pinterest-p"></i> <span>Pinterest</span>
            </a>
          </li>
        </ul>
      </div>
    );
  }

  return (
    <div className="article-content">
      <InfiniteScroll
        dataLength={articlesInfo.data.length}
        next={fetchData}
        hasMore={articlesInfo.hasMore}
        loader={renderLoading()}
      >
        {articlesInfo.data.map((artItem, key) => (
          <section key={artItem.id}>
            <div className="row content article-header-parent">
            <div className={`article-header ${key != 0 ? "col-lg-8 col-md-12 part-width" : ""}`}>
              <div className={`article-info info-top`}>
                <p>Sports</p>

                <h2 className="article-title">{artItem.title}</h2>

                <span class="ft-meta">
                  <a
                    href="https://sportscroll.com/author/darren/"
                    class="p_author"
                  >
                    <strong>Darren</strong>
                  </a>
                  <span class="sps-line"> - </span>
                  <span class="pdate">December 14, 2021 </span>
                </span>
              </div>

              <img
                className="article-big-image ft-dynamic-size-bg"
                src={getTopImage(artItem)}
                alt=""
              />
              <div className="article-info info-bottom">
                <p>Sports</p>

                <h2 className="article-title">{artItem.title}</h2>

                <span class="ft-meta">
                  <a
                    href="https://sportscroll.com/author/darren/"
                    class="p_author"
                  >
                    <strong>Darren</strong>
                  </a>
                  <span class="sps-line"> - </span>
                  <span class="pdate">December 14, 2021 </span>
                </span>
              </div>

              {renderPostBlock()}
            </div>
            </div>

            <div className="row article-base-content content">
              <ArticleItem
                articleItem={artItem}
                adUnitKeys={adUniteKeysByIds[artItem.id] || []}
              />

              <div className="sidebar col-lg-4" />
            </div>
          </section>
        ))}
      </InfiniteScroll>
    </div>
  );
}
