import "./Navbar.css";

export default function Navbar() {
  return (
    <div className="navbar-content">
      <div className="top-header container-part">
        <div className="badge-icon">
          <img
            src="https://cdn.sportscroll.com/wp-content/themes/new-theme/images/sps-logo.png"
            alt="Badge"
          />
        </div>

        <div className="search-icon">
          <img src="https://img.icons8.com/ios/50/000000/search--v3.png"/>
        </div>
      </div>

      <div className="navbar">
        <div className="links container-part">
            <span>NBA</span>
            <span>NFL</span>
            <span>College Sports</span>
            <span>Soccer</span>
            <span>Auto Racing</span>
        </div>
      </div>
    </div>
  );
}
